import React, { useState } from 'react'
import { Link, useHref } from 'react-router-dom/dist'
import { BsBank, BsCollection, BsHouseGearFill, BsTreeFill } from "react-icons/bs";
import { MdMiscellaneousServices } from "react-icons/md";
import { SiChevrolet } from "react-icons/si";
import { IoIosArrowBack, IoIosArrowDown} from 'react-icons/io';
import { FaBath} from 'react-icons/fa';
import { TbBuildingCommunity, TbNumbers } from "react-icons/tb";
import { FaBoxesStacked, FaBuilding, FaCar, FaCarSide, FaGasPump, FaKitchenSet, FaMoneyBill1Wave, FaMoneyBillWave, FaRegNewspaper, FaShop } from "react-icons/fa6";
import { PiPaintBrushHouseholdFill } from "react-icons/pi";
import { GiCarWheel, GiFurnace, GiSellCard } from "react-icons/gi";
import { LiaCarCrashSolid } from "react-icons/lia";
import { BiCategory } from "react-icons/bi";
export default function Menu() {
  const href=useHref()
  const [house_drop, sethouse_drop]=useState(false)
  const [house_service_drop, sethouse_service_drop]=useState(false)
  const [house_product_drop, sethouse_product_drop]=useState(false)
  const [house_construction_drop, sethouse_construction_drop]=useState(false)
  const [car_product_drop, setcar_product_drop]=useState(false)
  const [car_service_drop, setcar_service_drop]=useState(false)
  const [car_drop, setcar_drop]=useState(false)
  console.log(href)
  return (
    <div className='menuDiv'>
     <div className='menuHeader'>
        UYAVTO.UZ
     </div>
     <div className='menuList'>
     <Link  className={`menuItem ${href==='/'?"active_menu":""}`} to={"/"}>
          <BsCollection /><p>Bosh sahifa</p>
        </Link>
        <Link className={`menuItem ${href.indexOf("b-type")!==-1?"active_menu":""}`} to={"/b-type"}>
        <FaShop /><p>Bozor turi</p>
        </Link>
        
       
        <Link className={`menuItem ${href.indexOf("itype")!==-1?"active_menu":""}`} to={"/itype"}>
        <BsBank /><p>Kredit turlari</p>
        </Link>
        <Link className={`menuItem ${href.indexOf("tarif")!==-1?"active_menu":""}`} to={"/tarif"}>
        <FaMoneyBill1Wave /><p>Tariflar</p>
        </Link>
        <Link className={`menuItem ${href.indexOf("news")!==-1?"active_menu":""}`} to={"/news"}>
        <FaRegNewspaper /><p>Yangiliklar</p>
        </Link>
        <div onClick={()=>{sethouse_drop(!house_drop)}} className={`drop_title ${house_drop?"opened_title":""}`}><span>Ko'chmas mulk</span> {house_drop?<IoIosArrowDown className='drop_icon'/>:<IoIosArrowBack className='drop_icon'/>}</div>
        <div className={`drop ${house_drop?"opened_drop_user":""}`}>
        <Link className={`menuItem ${href.indexOf("home-ads")!==-1?"active_menu":""}`} to={"/home-ads"}>
        <TbBuildingCommunity /><p>Ko'chmas mulk e'lonlari</p>
        </Link>
        <Link className={`menuItem ${href.indexOf("house-type")!==-1?"active_menu":""}`} to={"/house-type"}>
        <FaBuilding /><p>Ko'chmas mulk turlari</p>
        </Link>
        <Link className={`menuItem ${href.indexOf("repair")!==-1?"active_menu":""}`} to={"/repair"}>
        <PiPaintBrushHouseholdFill /><p>Tamir turlari</p>
        </Link>
        <Link className={`menuItem ${href.indexOf("mat-type")!==-1?"active_menu":""}`} to={"/mat-type"}>
        <FaBoxesStacked /><p>Qurilish turlari</p>
        </Link>
        <Link className={`menuItem ${href.indexOf("sanuzel")!==-1?"active_menu":""}`} to={"/sanuzel"}>
        <FaBath /><p>Sanuzel turlari</p>
        </Link>
        <Link className={`menuItem ${href.indexOf("near")!==-1?"active_menu":""}`} to={"/near"}>
        <BsTreeFill /><p>Yaqin atrofda</p>
        </Link>
        <Link className={`menuItem ${href.indexOf("items-in")!==-1?"active_menu":""}`} to={"/items-in"}>
        <GiFurnace /><p>Uy jihozlari</p>
        </Link>
        </div>
        <div onClick={()=>{sethouse_service_drop(!house_service_drop)}} className={`drop_title ${house_service_drop?"opened_title":""}`}><span>Uy xizmatlari</span> {house_service_drop?<IoIosArrowDown className='drop_icon'/>:<IoIosArrowBack className='drop_icon'/>}</div>
        <div className={`drop ${house_service_drop?"opened_drop_user":""}`}>
        {/* <Link className={`menuItem ${href.indexOf("home-service-ads")!==-1?"active_menu":""}`} to={"/home-service-ads"}>
        <TbBuildingCommunity /><p>Uy xizmatlari e'lonlari</p>
        </Link> */}
        <Link className={`menuItem ${href.indexOf("house-service-types")!==-1?"active_menu":""}`} to={"/house-service-types"}>
        <BsHouseGearFill /><p>Xizmat turlari</p>
        </Link>
        <Link className={`menuItem ${href.indexOf("house-payment-type")!==-1?"active_menu":""}`} to={"/house-payment-type"}>
        <FaMoneyBillWave /><p>To'lov turlari</p>
        </Link>
        </div>
        <div onClick={()=>{sethouse_product_drop(!house_product_drop)}} className={`drop_title ${house_product_drop?"opened_title":""}`}><span>Uy-ro'zg'or buyumlari</span> {house_product_drop?<IoIosArrowDown className='drop_icon'/>:<IoIosArrowBack className='drop_icon'/>}</div>
        <div className={`drop ${house_product_drop?"opened_drop_user":""}`}>
        <Link className={`menuItem ${href.indexOf("house-product-type")!==-1?"active_menu":""}`} to={"/house-product-type"}>
        <BiCategory /><p>Katalog</p>
        </Link>
        <Link className={`menuItem ${href.indexOf("house-subproduct-type")!==-1?"active_menu":""}`} to={"/house-subproduct-type"}>
        <FaKitchenSet /><p>Mahsulot turi</p>
        </Link>
        </div>
        <div onClick={()=>{sethouse_construction_drop(!house_construction_drop)}} className={`drop_title ${house_construction_drop?"opened_title":""}`}><span>Qurilish mollari</span> {house_construction_drop?<IoIosArrowDown className='drop_icon'/>:<IoIosArrowBack className='drop_icon'/>}</div>
        <div className={`drop ${house_construction_drop?"opened_drop_user":""}`}>
        <Link className={`menuItem ${href.indexOf("house-construction-type")!==-1?"active_menu":""}`} to={"/house-construction-type"}>
        <BiCategory /><p>Katalog</p>
        </Link>
        <Link className={`menuItem ${href.indexOf("house-subconstruction-type")!==-1?"active_menu":""}`} to={"/house-subconstruction-type"}>
        <FaKitchenSet /><p>Mahsulot turi</p>
        </Link>
        </div>
        
       
        <div onClick={()=>{setcar_drop(!car_drop)}} className={`drop_title ${car_drop?"opened_title":""}`}><span>Avtomashina</span> {car_drop?<IoIosArrowDown className='drop_icon'/>:<IoIosArrowBack className='drop_icon'/>}</div>
        <div className={`drop ${car_drop?"opened_drop_user":""}`}>
        <Link className={`menuItem ${href.indexOf("car-ads")!==-1?"active_menu":""}`} to={"/car-ads"}>
        <TbBuildingCommunity /><p>Avtotransport e'lonlari</p>
        </Link>
        <Link className={`menuItem ${href.indexOf("cmtype")!==-1?"active_menu":""}`} to={"/cmtype"}>
        <SiChevrolet /><p>Avtomobil turi</p>
        </Link>
        <Link className={`menuItem ${href.indexOf("ctype")!==-1?"active_menu":""}`} to={"/ctype"}>
        <SiChevrolet /><p>Markalar</p>
        </Link>
       
        <Link className={`menuItem ${href.indexOf("model")!==-1?"active_menu":""}`} to={"/model"}>
        <FaCar /><p>Modellar</p>
        </Link>
        <Link className={`menuItem ${href.indexOf("position")!==-1?"active_menu":""}`} to={"/position"}>
        <TbNumbers /><p>Pozitsiyalar</p>
        </Link>
        <Link className={`menuItem ${href.indexOf("kuzov")!==-1?"active_menu":""}`} to={"/kuzov"}>
        <FaCarSide /><p>Kuzovlar</p>
        </Link>
        
        <Link className={`menuItem ${href.indexOf("transmission")!==-1?"active_menu":""}`} to={"/transmission"}>
        <GiSellCard /><p>Uzatish qutilari</p>
        </Link>
        <Link className={`menuItem ${href.indexOf("drive-unit")!==-1?"active_menu":""}`} to={"/drive-unit"}>
        <GiCarWheel /><p>Uzatmalar</p>
        </Link>
        <Link className={`menuItem ${href.indexOf("color")!==-1?"active_menu":""}`} to={"/color"}>
        <PiPaintBrushHouseholdFill /><p>Mashina ranglari</p>
        </Link>
        <Link className={`menuItem ${href.indexOf("color-state")!==-1?"active_menu":""}`} to={"/color-state"}>
        <LiaCarCrashSolid /><p>Kuzov holati</p>
        </Link>
        <Link className={`menuItem ${href.indexOf("engine-type")!==-1?"active_menu":""}`} to={"/engine-type"}>
        <FaGasPump /><p>Yonilg'i turlari</p>
        </Link>
        </div>
        <div onClick={()=>{setcar_service_drop(!car_service_drop)}} className={`drop_title ${car_service_drop?"opened_title":""}`}><span>Mashina xizmatlari</span> {car_service_drop?<IoIosArrowDown className='drop_icon'/>:<IoIosArrowBack className='drop_icon'/>}</div>
        <div className={`drop ${car_service_drop?"opened_drop_user":""}`}>
        <Link className={`menuItem ${href.indexOf("car-service-types")!==-1?"active_menu":""}`} to={"/car-service-types"}>
        <MdMiscellaneousServices /><p>Xizmat turlari</p>
        </Link>
        <Link className={`menuItem ${href.indexOf("car-payment-type")!==-1?"active_menu":""}`} to={"/car-payment-type"}>
        <FaMoneyBillWave /><p>To'lov turlari</p>
        </Link>
        
        </div>
        <div onClick={()=>{setcar_product_drop(!car_product_drop)}} className={`drop_title ${car_product_drop?"opened_title":""}`}><span>Ehtiyot qismlar</span> {car_product_drop?<IoIosArrowDown className='drop_icon'/>:<IoIosArrowBack className='drop_icon'/>}</div>
        <div className={`drop ${car_product_drop?"opened_drop_user":""}`}>
        <Link className={`menuItem ${href.indexOf("car-product-type")!==-1?"active_menu":""}`} to={"/car-product-type"}>
        <BiCategory /><p>Katalog</p>
        </Link>
        <Link className={`menuItem ${href.indexOf("car-subproduct-type")!==-1?"active_menu":""}`} to={"/car-subproduct-type"}>
        <GiCarWheel /><p>Mahsulot turi</p>
        </Link>
        </div>
       
     </div>
    </div>
  )
}
