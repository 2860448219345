import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getNewsHttp=async(data, token)=>{
    var res=await httpRequestGet('/admin/news/', data, token)
    return(res)
}
export const setNewsHttp=async(data, token)=>{
    var res=await httpRequestPost('/admin/news/', data, token)
    return(res)
}
export const setNewsImageHttp=async(data, token)=>{
    var res=await httpRequestPost('/admin/news-images/', data, token)
    return(res)
}
export const editNewsHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/admin/news/${id}/`, data, token)
    return(res)
}
export const deleteNewsHttp=async(data, token)=>{
    var res=await httpRequestDelete(`/admin/news/${data}/`, token)
    return(res)
}