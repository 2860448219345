import React, { useEffect } from 'react'
import Menu from '../components/Menu'
import { useTranslation } from 'react-i18next'
import { useHref } from 'react-router-dom';
import {Routes, Route} from 'react-router-dom'
import BType from './BType';
import HouseType from './HouseType';
import ItemsIn from './ItemsIn';
import Near from './Near';
import MatType from './MatType';
import Repair from './Repair';
import Sanuzel from './Sanuzel';
import HouseProductType from './HouseProductType';
import CarProductType from './CarProductType';
import Color from './Color';
import ColorState from './ColorState';
import DriveUnit from './DriveUnit';
import CType from './CType';
import EngineType from './EngineType';
import Kuzov from './Kuzov';
import IType from './IType';
import Transmission from './Transmission';
import HouseSubProductType from './HouseSubProductType';
import CarSubProductType from './CarSubProductType';
import HouseServiceType from './HouseServiceType';
import HouseServicePaymentType from './HouseServicePaymentType';
import CarServiceType from './CarServiceType';
import CarServicePaymentType from './CarServicePaymentType';
import Models from './Models';
import Position from './Position';
import CMType from './CMType';
import Tarifs from './Tarifs';
import HouseAds from './HouseAds';
import HouseAdsOne from './HouseAdsOne';
import HouseServiceAds from './HouseServiceAds';
import HouseServiceAdsOne from './HouseServiceAdsOne';
import CarAds from './CarAds';
import CarAdsOne from './CarAdsOne';
import News from './News';
import HouseConstructionType from './HouseConstructionType';
import HouseSubConstructionType from './HouseSubConstructionType';
export default function Home() {
   const {t} =useTranslation()
   const history = useHref();
   
  return (
    <div className='bigBox'>
        <div className='bigMenu'>
            <Menu/>
        </div>
        <div className='bigContent'>
          
        <Routes>
            <Route path=""/>
            <Route path="b-type" element={<BType/>}/>
            <Route path="house-type" element={<HouseType/>}/>
            <Route path="items-in" element={<ItemsIn/>}/>
            <Route path="near" element={<Near/>}/>
            <Route path="itype" element={<IType/>}/>
            <Route path="news" element={<News/>}/>
            <Route path="home-ads" element={<HouseAds/>}/>
            <Route path="home-ads/:id" element={<HouseAdsOne/>}/>
            <Route path="car-ads" element={<CarAds/>}/>
            <Route path="car-ads/:id" element={<CarAdsOne/>}/>
            <Route path="home-service-ads" element={<HouseServiceAds/>}/>
            <Route path="home-service-ads/:id" element={<HouseServiceAdsOne/>}/>
            <Route path="tarif" element={<Tarifs/>}/>
            <Route path="repair" element={<Repair/>}/>
            <Route path="house-product-type" element={<HouseProductType/>}/>
            <Route path="house-construction-type" element={<HouseConstructionType/>}/>
            <Route path="house-payment-type" element={<HouseServicePaymentType/>}/>
            <Route path="car-payment-type" element={<CarServicePaymentType/>}/>
            <Route path="house-service-types" element={<HouseServiceType/>}/>
            <Route path="car-service-types" element={<CarServiceType/>}/>
            <Route path="house-subproduct-type" element={<HouseSubProductType/>}/>
            <Route path="house-subconstruction-type" element={<HouseSubConstructionType/>}/>
            <Route path="car-subproduct-type" element={<CarSubProductType/>}/>
            <Route path="car-product-type" element={<CarProductType/>}/>
            <Route path="sanuzel" element={<Sanuzel/>}/>
            <Route path="model" element={<Models/>}/>
            <Route path="position" element={<Position/>}/>
            <Route path="mat-type" element={<MatType/>}/>
            <Route path="engine-type" element={<EngineType/>}/>
            <Route path="kuzov" element={<Kuzov/>}/>
            <Route path="transmission" element={<Transmission/>}/>
            <Route path="color" element={<Color/>}/>
            <Route path="ctype" element={<CType/>}/>
            <Route path="cmtype" element={<CMType/>}/>
            <Route path="drive-unit" element={<DriveUnit/>}/>
            <Route path="color-state" element={<ColorState/>}/>
        </Routes>
    
        </div>
    </div>
  )
}
