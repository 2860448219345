import { Button, Form, Input } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { dangerAlert, successAlert } from '../components/Alert'
import { login } from '../host/requests/Auth'
import { useStore } from '../store/Store'
import { getUserFilter, getUserProfile } from '../host/requests/User'
import { checkUsersData } from '../components/Checkers'
import Cookies from 'js-cookie';

export default function Login() {
  const settoken_access=useStore(state=>state.settoken_access)
    const settoken_refresh=useStore(state=>state.settoken_refresh)
    const setuser=useStore(state=>state.setuser)
    const setfilter=useStore(state=>state.setfilter)
    const setloader=useStore(state=>state.setloader)
    const {t}=useTranslation()
    const  onFinish=async(val)=>{
      setloader(true)
      try{
        var res=await login(val)
        // if(res.data.is_staff){
          settoken_access(res.data.access)
          settoken_refresh(res.data.refresh)
          Cookies.set('access_token_admin_uyavto', res.data.access, { expires: 3/24 });
          Cookies.set('refresh_token_admin_uyavto', res.data.refresh, { expires: 3/24 });
        // }else{
        //   dangerAlert("Bu loginda kirish huquqi yo'q")
        // }
       setloader(false)
       }catch (error){
        setloader(false)
        if(!!error.response.data.detail){
          dangerAlert("Login yoki parol xato")
        }else{
          dangerAlert("Texnik nosozlik yuzaga keldi. Keyinroq qaytib urinib ko'ring")}
      }
    }
  return (
    <div className='login_big_box'>
        <div className='form_box'>
        <div className='red_box_form'></div>
            <div className='blue_box_form'></div>
            
        <div className='login_box'>
            
            <h1>{t("login")}</h1>
        <Form
    name="basic"
    labelCol={{
      span: 24,
    }}
    wrapperCol={{
      span: 24,
    }}
   initialValues={{
      remember: true,
    }}
    onFinish={onFinish}
    // onFinishFailed={onFinishFailed}
    
  >
    <Form.Item
      label="Email"
      name="email"
      rules={[
       
        {
           required: true,
           message: "",
            
        }
      ]}
    >
      <Input className='input' />
    </Form.Item>

    <Form.Item
      label={"Parol"}
      name="password"
      rules={[
        {
          required: true,
          message: "",
        },
      ]}
    >
      <Input.Password className='input'  autoComplete="new-password"/>
    </Form.Item>
     <Form.Item
      wrapperCol={{
        span: 24,
      }}
    >
      <Button className='login_btn' type="primary" htmlType="submit">
        {t("login")}
      </Button>
    </Form.Item>
  </Form>
 
        </div>
        </div>
    </div>
  )
}
