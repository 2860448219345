import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getHouseSubConstructionTypeHttp=async(data, token)=>{
    var res=await httpRequestGet('/admin/qurilish-subproduct_type/', data, token)
    return(res)
}
export const getHouseSubConstructionTypeCatalogsHttp=async(data, token)=>{
    var res=await httpRequestGet('/admin/houses-qurilish-products/types/', data, token)
    return(res)
}
export const setHouseSubConstructionTypeHttp=async(data, token)=>{
    var res=await httpRequestPost('/admin/qurilish-subproduct_type/', data, token)
    return(res)
}
export const editHouseSubConstructionTypeHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/admin/qurilish-subproduct_type/${id}/update/`, data, token)
    return(res)
}
export const deleteHouseSubConstructionTypeHttp=async(data, token)=>{
    var res=await httpRequestDelete(`/admin/qurilish-subproduct_type/${data}/delete/`, token)
    return(res)
}